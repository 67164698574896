import { TomOption } from 'tom-select/src/types'
import { escape_html } from 'tom-select/src/utils'
import SelectableController from './selectable_controller'

const openFacilityIcon = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" class="h-4 w-4 text-primary dark:text-primary-dark shrink-0">' +
  '<path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"></path>' +
  '</svg>'

interface FacilityTomOption extends TomOption {
  name: string
  licenseNumber: string
  url: string
}

export default class extends SelectableController {
  settings = {
    searchField: ['name', 'licenseNumber'],
    plugins: ['checkbox_options', 'remove_button', 'virtual_scroll']
  }

  buildUrl (query: string): string {
    if (query === '') {
      return `/facilities?page=${this.currentPage}`
    }

    return `/facilities?page=${this.currentPage}&advanced_search=true&columns[]=fields.license_number&q[name_cont]=` +
      `&q[g][0][c][0][a][0][name]=fields.license_number&q[g][0][c][0][p]=cont&q[g][0][c][0][v][0][value]=${encodeURIComponent(query)}` +
      `&q[g][0][c][1][a][0][name]=name&q[g][0][c][1][p]=cont&q[g][0][c][1][v][0][value]=${encodeURIComponent(query)}` +
      '&q[g][0][m]=or'
  }

  renderOption (data: FacilityTomOption, escape: typeof escape_html): HTMLElement {
    const optionElement = document.createElement('div')
    optionElement.innerHTML = `<div class="option-link"><div class="flex items-center space-x-1.5"><p>${escape(data.name)}</p>` +
      `<a href="${escape(data.url)}" target="_blank">${openFacilityIcon}</a></div>` +
      `<p class="text-sm font-normal text-gray-500 dark:text-gray-400">${escape(data.licenseNumber)}</p></div>`

    const facilityAnchor = optionElement.querySelector('a') as HTMLAnchorElement
    facilityAnchor.addEventListener('click', (event) => {
      event.stopPropagation()
    })

    return optionElement
  }

  renderItem (data: FacilityTomOption, escape: typeof escape_html): HTMLElement {
    const optionElement = document.createElement('div')
    optionElement.innerHTML = '<div class="flex flex-row items-center w-full">' +
      `<a class="h-4 w-4" href="${escape(data.url)}" target="_blank">${openFacilityIcon}</a>` +
      `<p class="px-1">${escape(data.name)}</p></div>`

    const facilityAnchor = optionElement.querySelector('a') as HTMLAnchorElement
    facilityAnchor.addEventListener('click', (event) => {
      event.stopPropagation()
    })

    return optionElement
  }
}
